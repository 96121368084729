import { useActivityTrackingConfigQuery } from '../../generatedX/graphql';

const useActivityTrackingConfig = () => {
  const { data, loading } = useActivityTrackingConfigQuery();

  const fallback = { config: null, loading };

  if (!data) return fallback;
  if (!data.tenantConfigTyped) return fallback;
  if (!data.tenantConfigTyped.clientConfiguration) return fallback;

  return {
    config: {
      endpoint:
        data.tenantConfigTyped.clientConfiguration
          .carrierActivityTrackingEndpoint,
      header:
        data.tenantConfigTyped.clientConfiguration
          .carrierActivityTrackingHeader,
      key: data.tenantConfigTyped.clientConfiguration
        .carrierActivityTrackingKey,
    },
    loading,
  };
};

export default useActivityTrackingConfig;
