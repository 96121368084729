import React from 'react';

const useWindowFocusHandlers = (
  skip: boolean,
  onFocus: () => void,
  onBlur: () => void,
) => {
  React.useEffect(() => {
    if (skip) return;

    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, [onFocus, onBlur, skip]);
};

export default useWindowFocusHandlers;
