import { useAuth0, User } from '@auth0/auth0-react';

export const useAuth = () => {
  const auth = useAuth0();
  const getValForKeyEnding = (user?: User, key?: string) => {
    if (!user || !key) {
      return '';
    }
    const fullKey = Object.keys(user).find(k => k.endsWith(key));
    return user[fullKey as keyof User];
  };

  const assuredUser = {
    name: auth?.user?.name,
    nickname: auth?.user?.nickname,
    sub: auth?.user?.sub,
    picture: auth?.user?.picture,
    god: getValForKeyEnding(auth?.user, 'god'),
    knockToken: getValForKeyEnding(auth?.user, 'KnockToken'),
    email: auth?.user?.email,
    email_verified: auth?.user?.email_verified,
    role: getValForKeyEnding(auth?.user, 'role'),
    tenant: getValForKeyEnding(auth?.user, 'tenant'),
    dataRentryMode: getValForKeyEnding(auth?.user, 'data_rentry_mode'),
    isTeamManager: getValForKeyEnding(auth?.user, 'is_team_manager'),
    isTeamMember: getValForKeyEnding(auth?.user, 'is_team_member'),
    userIdForCarrier: auth?.user?.app_metadata?.repCode,
  };
  return { ...auth, user: assuredUser };
};
