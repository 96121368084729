import { History } from 'history';
import React, { Suspense } from 'react';
import { Redirect, Route, Router } from 'react-router-dom';
import { useFeatureFlag } from '~/src/hooks/useFeatureFlag';

import PageLoader from '@assured/frontend/src/components/PageLoader';

import config from '../../../config';
import {
  ANALYTICS_ALLOWED_ROLES,
  FEATURE_FLAG_ANALYTICS_DASHBOARD,
  FEATURE_FLAG_CAT_ANALYTICS_DASHBOARD,
  FEATURE_FLAG_CAT_ANALYTICS_DASHBOARD_V2,
} from '../../AnalyticsDashboard/constants';
import OutOfOfficeIgnoredProvider from '../../CaseView2/messaging/OutOfOfficeSettings/OutOfOfficeIgnoredProvider';
import OutOfOfficeSettingsModal from '../../CaseView2/messaging/OutOfOfficeSettings/OutOfOfficeSettingsModal';
import { SHOW_REAL_CAT } from '../../Cat/constants';
import Layout from '../../Layout/Layout';
import { OpenClaimRedirector } from '../../OpenClaimRedirector/OpenClaimRedirector';
import { Placeholder } from '../../shared/Placeholder';
import { FeatureFlaggedRoute } from './FeatureFlaggedRoute';
import { ProtectedRoute } from './ProtectedRoute';
import ActivityTracking from '../../ActivityTracking/ActivityTracking';

const MessagingContainer = React.lazy(
  () => import('../../Messaging/MessagingContainer'),
);

const Welcome = React.lazy(() => import('../../Welcome'));
const UserManagement = React.lazy(() => import('../../UserManagement'));
const Settings = React.lazy(() => import('../../Settings'));
const IntegrationTestBench = React.lazy(
  () => import('../../IntegrationTestBench'),
);

const AnalyticsDashboard = React.lazy(() => import('../../AnalyticsDashboard'));

const ClaimDashboard = React.lazy(() => import('../../ClaimDashboard'));
const ClaimView = React.lazy(() => import('../../ClaimView'));
const ClaimView2 = React.lazy(() => import('../../ClaimView2/ClaimView2'));

const CaseDashboard = React.lazy(() => import('../../CaseDashboard'));
const CaseView = React.lazy(() => import('../../CaseView'));
const CaseView2 = React.lazy(() => import('../../CaseView2'));

const PluginDashboard = React.lazy(() => import('../../PluginDashboard'));

const CatMapDemo = React.lazy(() => import('../../CatDemo/CatMap'));
const CatFlowDemo = React.lazy(() => import('../../CatDemo/CatFlow'));
const CatManagementDemo = React.lazy(
  () => import('../../CatDemo/CatManagement'),
);
const CatDashboard = React.lazy(() => import('../../Cat/CatDashboard'));

const CatFlow = React.lazy(() => import('../../Cat/CatFlow'));
const CatManagement = React.lazy(
  () => import('../../Cat/CatManagement/CatManagement'),
);

const CaseViewStandaloneRenderer = React.lazy(
  () => import('../../CaseViewStandaloneRenderer'),
);
const ClaimViewStandaloneRenderer = React.lazy(
  () => import('../../ClaimViewStandaloneRenderer'),
);
const SidekickContainer = React.lazy(
  () => import('../../Sidekick/SidekickContainer'),
);
const FlowBuilder = React.lazy(() => import('../../FlowBuilder'));

const AssuredLayout = () => {
  return (
    <Layout navigation={[]}>
      <PageLoader style={{ minHeight: '80rvh' }} text="Loading Assured..." />
    </Layout>
  );
};

const CaseViewFlagged = () => {
  const cv2Enabled = useFeatureFlag('claim-view-2-0-phase-1', false);

  return (
    <>
      {cv2Enabled !== undefined && !cv2Enabled && (
        <Route path="/cases/:id/:perspective?" component={CaseView} />
      )}
      {cv2Enabled !== undefined && cv2Enabled && (
        <Route path="/cases/:id/:perspective?" component={CaseView2} />
      )}
    </>
  );
};

const ClaimViewFlagged = () => {
  const cv2Enabled = useFeatureFlag('claim-view-2-0-phase-1', false);

  return (
    <>
      {cv2Enabled !== undefined && !cv2Enabled && (
        <Route path="/claims/:id/:view?" component={ClaimView} />
      )}
      {cv2Enabled !== undefined && cv2Enabled && (
        <Route path="/claims/:id/:view?" component={ClaimView2} />
      )}
    </>
  );
};

export const RouteProvider = ({ history }: { history: History }) => {
  const catEnabled = useFeatureFlag(SHOW_REAL_CAT, false);

  return (
    <Router history={history}>
      <ActivityTracking />
      <OutOfOfficeSettingsModal>
        <Suspense fallback={<AssuredLayout />}>
          <ProtectedRoute exact path="/" component={Welcome} />

          <ProtectedRoute
            exact
            path="/cases"
            component={CaseDashboard}
            condition={(c, u) =>
              u?.role !== 'direct_access_only' &&
              u?.role !== 'manager_direct_access_only'
            }
          />

          <ProtectedRoute
            exact
            path="/claims"
            component={ClaimDashboard}
            condition={(c, u) =>
              u?.role !== 'direct_access_only' &&
              u?.role !== 'manager_direct_access_only'
            }
          />

          <ProtectedRoute path="/plugins" component={PluginDashboard} />
          <ProtectedRoute path="/users" component={UserManagement} />
          <ProtectedRoute path="/settings" exact component={Settings} />

          <Route
            exact
            path="/claims/:id"
            render={props => (
              <Redirect to={`/claims/${props.match.params.id}/general`} />
            )}
          />
          <ProtectedRoute
            path="/claims/:id/:view?"
            component={ClaimViewFlagged}
          />
          <Route
            path="/claim/"
            render={props => (
              <Redirect
                to={`/claims/${props.location.pathname.substring(
                  '/claim/'.length,
                )}${props.location.search}`}
              />
            )}
          />
          <ProtectedRoute path="/claims1/:id/:view?" component={ClaimView} />
          <Route
            path="/claim1/"
            render={props => (
              <Redirect
                to={`/claims1/${props.location.pathname.substring(
                  '/claim1/'.length,
                )}${props.location.search}`}
              />
            )}
          />

          <ProtectedRoute
            path="/cases/:id/:perspective?"
            component={CaseViewFlagged}
          />
          <ProtectedRoute
            path="/cases1/:id/:perspective?"
            component={CaseView}
          />

          {/* Need a placeholder here in order to trigger auth when needed.
           * Once the catEnabled FF goes away, this can be removed */}
          {catEnabled === undefined && (
            <ProtectedRoute
              path="/cat/dashboard"
              // eslint-disable-next-line react/no-unstable-nested-components
              component={() => (
                <Placeholder message="Authorizing access to Assured ClaimView..." />
              )}
            />
          )}
          {/* CAT Administrator */}
          {catEnabled !== undefined &&
            (catEnabled ? (
              <>
                <ProtectedRoute
                  path="/cat/dashboard"
                  component={CatDashboard}
                />
                <ProtectedRoute
                  path="/cat/management"
                  component={CatManagement}
                  condition={(c, u) => u?.god === true}
                />
              </>
            ) : (
              <>
                <ProtectedRoute
                  path="/cat/management"
                  component={CatManagementDemo}
                />
                <Route path="/cat/dashboard">
                  <Layout navigation={[{ current: true, name: 'CAT' }]}>
                    <CatMapDemo />
                  </Layout>
                </Route>
              </>
            ))}

          <Route exact path="/cat">
            <Redirect to="/cat/dashboard" />
          </Route>

          <Route path="/cat/configurator">
            <Layout
              navigation={[
                { href: '/cat', name: 'CAT' },
                { current: true, name: 'Configurator' },
              ]}
            >
              {catEnabled ? <CatFlow /> : <CatFlowDemo />}
            </Layout>
          </Route>

          {/* Analytics Dashboard */}
          <FeatureFlaggedRoute featureFlag={FEATURE_FLAG_ANALYTICS_DASHBOARD}>
            <>
              <Route exact path="/analytics">
                <Redirect to="/analytics/claims" />
              </Route>
              <ProtectedRoute
                exact
                path="/analytics/claims"
                component={AnalyticsDashboard}
                condition={(c, u) =>
                  !!u?.role && ANALYTICS_ALLOWED_ROLES.includes(u.role)
                }
              />
              <FeatureFlaggedRoute
                featureFlag={FEATURE_FLAG_CAT_ANALYTICS_DASHBOARD}
              >
                <ProtectedRoute
                  exact
                  path="/analytics/cat"
                  component={AnalyticsDashboard}
                  condition={(c, u) =>
                    !!u?.role && ANALYTICS_ALLOWED_ROLES.includes(u.role)
                  }
                />
              </FeatureFlaggedRoute>
              <FeatureFlaggedRoute
                featureFlag={FEATURE_FLAG_CAT_ANALYTICS_DASHBOARD_V2}
              >
                <ProtectedRoute
                  exact
                  path="/analytics/catV2"
                  component={AnalyticsDashboard}
                  condition={(c, u) =>
                    !!u?.role && ANALYTICS_ALLOWED_ROLES.includes(u.role)
                  }
                />
              </FeatureFlaggedRoute>
            </>
          </FeatureFlaggedRoute>

          {/* Sidekick */}
          <ProtectedRoute
            path="/sidekick_begin"
            component={SidekickContainer}
          />
          <ProtectedRoute
            path="/sidekick/:claimId?/:workflowId?"
            component={SidekickContainer}
          />

          {/* Flow Builder */}
          <Route path="/settings/flow_builder">
            <Layout
              navigation={[
                { href: '/settings', name: 'Settings' },
                { current: true, name: 'Flow Builder' },
              ]}
            >
              <FlowBuilder />
            </Layout>
          </Route>

          <ProtectedRoute path="/open_claim" component={OpenClaimRedirector} />
        </Suspense>
      </OutOfOfficeSettingsModal>

      <OutOfOfficeIgnoredProvider>
        <Suspense fallback={<AssuredLayout />}>
          {/* Integration testing */}
          <ProtectedRoute
            path="/_integration_test_bench/:claimId?/:payloadType?/:carrier?"
            component={IntegrationTestBench}
          />

          {/* Renderer for CaseView used for PDF generation */}
          <Route
            path="/_caseview_renderer"
            component={CaseViewStandaloneRenderer}
          />

          {/* Renderer for ClaimView used for PDF generation */}
          <Route
            path="/_claimview_renderer"
            component={ClaimViewStandaloneRenderer}
          />

          {config.enableMessaging ? <MessagingContainer /> : null}
        </Suspense>
      </OutOfOfficeIgnoredProvider>
    </Router>
  );
};
