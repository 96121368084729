import { useRouteMatch } from 'react-router-dom';
import {
  useGetCaseNumberQuery,
  useGetClaimNumberQuery,
} from '../../generatedX/graphql';

const useActiveDomainObject = () => {
  const caseRouteMatch = useRouteMatch<{ id: string }>('/cases/:id');

  const claimRouteMatch = useRouteMatch<{ id: string }>('/claims/:id');

  const caseId = caseRouteMatch?.params.id;
  const { data: caseData, loading: caseLoading } = useGetCaseNumberQuery({
    variables: {
      id: caseId ?? '',
    },
    skip: !caseId,
  });

  const claimId = claimRouteMatch?.params.id;
  const { data: claimData, loading: claimLoading } = useGetClaimNumberQuery({
    variables: {
      id: claimId ?? '',
    },
    skip: !claimId,
  });

  if (!caseId && !claimId) return null;

  if (caseId && caseData && caseData.case) {
    return {
      id: caseData.case.id,
      number: caseData.case.externalId,
      type: 'Case' as const,
    };
  }

  if (claimId && claimData && claimData.claim) {
    return {
      id: claimData.claim.id,
      number: claimData.claim.confirmationNumber,
      type: 'Case' as const,
    };
  }

  if (caseLoading || claimLoading) return null;

  return null;
};

export default useActiveDomainObject;
