export const ANALYTICS_CHART_TIME_ZONE = 'America/Los_Angeles';
export const FEATURE_FLAG_ANALYTICS_DASHBOARD = 'analytics-dashboard';
export const FEATURE_FLAG_CAT_ANALYTICS_DASHBOARD = 'cat-analytics-dashboard';
export const FEATURE_FLAG_CAT_ANALYTICS_DASHBOARD_V2 =
  'cat-analytics-dashboard-v-2';
export const ANALYTICS_DASHBOARD_ICON_VISIBLE =
  'analytics-dashboard-icon-visible';

export const ANALYTICS_ALLOWED_ROLES = [
  'administrator',
  'administrator_read_only',
];
